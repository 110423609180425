define('ember-css-url/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = cssUrl;
  function cssUrl(propertyName, url) {
    if (!/^[-a-zA-Z]+$/.test(propertyName)) {
      throw new Error('Potentially unsafe property name ' + propertyName);
    }
    if (!url) {
      return;
    }

    // Step 1: Make sure there are no un-encoded double quotes
    var encodedURL = url.replace(/"/g, '%22');

    // Step 2: if there is a protocol present, whitelist only http and
    // https. This prevents shenanigans like "javascript://" URLs (which
    // certain older browsers may execute).
    var m = /^([^:]+):/.exec(encodedURL);
    if (m) {
      var proto = m[1].toLowerCase();
      if (proto !== 'http' && proto !== 'https') {
        throw new Error('disallowed protocol in css url: ' + url);
      }
    }

    // Step 3: Use our own double quotes, which the url cannot break out
    // of due to Step 1.
    return Ember.String.htmlSafe(propertyName + ': url("' + encodedURL + '")');
  }
});